import { Component, OnInit, Injectable } from '@angular/core';
import { AuthService } from 'src/app/shared/service/auth.service';
import {Memoize} from 'typescript-memoize';

@Component({
  selector: 'app-base',
  template: '',  
  providers: [AuthService]
})

@Injectable()
export class BaseComponent implements OnInit {

  constructor(protected authService: AuthService) { }

  ngOnInit() {
  }

  @Memoize()
  isGranted(roles: string []){
    return this.authService.isGranted(roles)
  }  
  
}
