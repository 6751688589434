import { Component, Injectable } from "@angular/core";
import { GenericComponent } from "src/app/components/generic/generic.component";
import { AuthService } from "src/app/shared/service/auth.service";
import { GrupoService } from "src/app/shared/service/grupo.service";
import { Grupo } from "src/app/shared/model/grupo.model";

@Component({
  selector: "app-grupo-list",
  templateUrl: "./grupo-list.component.html",
  styleUrls: ["./grupo-list.component.css"],
  providers: [GrupoService],
})
@Injectable()
export class GrupoListComponent extends GenericComponent<Grupo> {
  constructor(
    protected service: GrupoService,
    protected authService: AuthService
  ) {
    super(authService);
  }

  protected getFilteredColumns(data: any): string[] {
    return [data.nome];
  }

  public getDisplayedColumns() {
    return ["nome", "details"];
  }
}
