import { Contrato } from './contrato.model';

export enum DISPOSITIVO_STATUS {
    Pendente = '0',
    Ativo = '1',
    Desativo = '2',
    Recusado = '9',
}

export enum BLOQUEIO_STATUS {
    Desbloqueado = '0',
    Bloqueado = '1',   
}

const status_list = { 
    "0": "Pendente",
    "1": "Ativo" ,
    "2": "Desativo" ,
    "9": "Recusado" ,
}

const bloqueio_list = { 
    "0": "Desbloqueado",
    "1": "Bloqueado",
}

export class Dispositivo {
    selector: string 
    identificador: string   
    marca: string
    modelo: string
    contrato: Contrato
    informacao_complementar: string
    status: number
    bloqueio: number  

    get statusDescricao() {
        return status_list[this.status]        
    }    
    
    get bloqueioDescricao() {
        return bloqueio_list[this.bloqueio]        
    }
}