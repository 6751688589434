import { Component, Injectable, Inject } from '@angular/core';
import { GenericComponent } from 'src/app/components/generic/generic.component';
import { DispositivoService } from 'src/app/shared/service/dispositivo.service';
import { Dispositivo, DISPOSITIVO_STATUS, BLOQUEIO_STATUS } from 'src/app/shared/model/dispositivo.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogMensagemBloqueio } from '../../dialog/dialog-mensagem-bloqueio.component';

@Component({
    selector: 'app-dispositivo-list',
    templateUrl: './dispositivo-list.component.html',
    styleUrls: ['./dispositivo-list.component.css'],
    providers: [DispositivoService]
})

@Injectable()
export class DispositivoListComponent extends GenericComponent<Dispositivo>  {
    public enumDispositivoStatus = DISPOSITIVO_STATUS;
    public enumBloqueioStatus = BLOQUEIO_STATUS;

    constructor(protected service: DispositivoService, protected authService: AuthService, private _snackBar: MatSnackBar, private dialog: MatDialog) {
        super(authService)
    }

    public getDisplayedColumns(): string[] {
        return ['identificador', 'marca_modelo', 'informacao_complementar', 'senha', 'contrato', 'status', 'bloqueio', 'details']
    }

    protected getFilteredColumns(data: any): string[] {
        return [data.identificador, data.marca, data.modelo, data.informacao_complementar,
        data.senha, data.contrato.codigo, data.statusDescricao];
    }

    ativar(element: Dispositivo) {
        this.service.ativar(element.selector).subscribe(
            (data: Dispositivo) => {
                let indexElement = this.dataSource.data.indexOf(element)
                this.dataSource.data[indexElement] = Object.assign(this.dataSource.data[indexElement], data);
            },
            (httpError: HttpErrorResponse) => {
                if (httpError.error == "Dispositivo - Error - Limite do contrato excedido.") {
                    this._snackBar.open("Limite de uso do contrato excedido!", "", { duration: 5000, panelClass: ['app-alert'] })
                }
            }
        );
    }

    desativar(element: Dispositivo) {
        this.service.desativar(element.selector).subscribe(
            (data: Dispositivo) => {
                let indexElement = this.dataSource.data.indexOf(element)
                this.dataSource.data[indexElement] = Object.assign(this.dataSource.data[indexElement], data);
            }
        );
    }

    bloquear(mensagem: string, element: Dispositivo) {
        this.service.bloquear(mensagem, element.selector).subscribe(
            (data: Dispositivo) => {
                let indexElement = this.dataSource.data.indexOf(element)
                this.dataSource.data[indexElement] = Object.assign(this.dataSource.data[indexElement], data);
            }
        );
    }

    desbloquear(element: Dispositivo) {
        this.service.desbloquear(element.selector).subscribe(
            (data: Dispositivo) => {
                let indexElement = this.dataSource.data.indexOf(element)
                this.dataSource.data[indexElement] = Object.assign(this.dataSource.data[indexElement], data);
            }
        );
    }

    openDialogBloqueio(element: Dispositivo): void {
        const dialogRef = this.dialog.open(DialogMensagemBloqueio, {
            width: '450px',
            data: { mensagem: "" }
        });

        dialogRef.afterClosed().subscribe(result => {            
            let mensagem = dialogRef.componentInstance.data.mensagem.trim()
            if (mensagem !== "") {
                this.bloquear(mensagem, element)
            }
        });
    }
}
