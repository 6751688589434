import { Component, Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    mensagem: string;
}

@Component({
    selector: 'dialog-mensagem-bloqueio',
    templateUrl: 'dialog-mensagem-bloqueio.component.html',
})
export class DialogMensagemBloqueio {

    constructor(
        public dialogRef: MatDialogRef<DialogMensagemBloqueio>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    onClickEnviar(): void {
        let mensagem = this.dialogRef.componentInstance.data.mensagem;
        if (mensagem.trim() !== "") {
            this.dialogRef.close();
        }
    }
}
