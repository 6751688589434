import { Component, Injectable } from '@angular/core';
import { GenericComponent } from 'src/app/components/generic/generic.component';
import { ContratoService } from 'src/app/shared/service/contrato.service';
import { Contrato } from 'src/app/shared/model/contrato.model';
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
  selector: 'app-contrato-list',
  templateUrl: './contrato-list.component.html',
  styleUrls: ['./contrato-list.component.css'],
  providers: [ContratoService]
})

@Injectable()
export class ContratoListComponent extends GenericComponent<Contrato>  {
  
  constructor(protected service: ContratoService, protected authService: AuthService) { 
    super(authService)
  }

  protected getFilteredColumns(data: any): string[] {    
    return [data.codigo, data.cliente.nome];
  }
  
  public getDisplayedColumns(): string[]{
    if(this.isGranted(['ROLE_MASTER'])){
      return ['codigo', 'cliente', 'limite', 'quantidade_dispositivo_ativo', 'details']
    }

    return ['codigo', 'limite', 'quantidade_dispositivo_ativo', 'details']
  }
}
