import { Component, OnInit, Injectable, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { MatSort } from '@angular/material/sort';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-generic',
  template: '',  
})
@Injectable()
export class GenericComponent<T> extends BaseComponent implements OnInit {

  public elements: T[] = []
  public element: T
  protected service
  public dataSource = new MatTableDataSource<T>(this.elements)
  protected router: Router
  protected form: FormGroup

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit() {
    this.list()
  }

  protected dataSourceManipulation(){
    this.dataSource = new MatTableDataSource<T>(this.elements);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSource.filterPredicate = (data, filter) => {
      let dataMapper = [];
      
      this.getFilteredColumns(data).forEach(element => {
        dataMapper.push(element);  
      });      
      
      let dataStr: string = dataMapper.join().trim().toLocaleLowerCase()      
      
      return dataStr.indexOf(filter) != -1;
    }    
  }

  protected getFilteredColumns(data: any): string[] {    
    return [];
  }

  applyFilter(filterValue: string) {    
    this.dataSource.filter = filterValue.trim().toLowerCase();    
  }  

  list() {
    this.service.list().subscribe(
      (data: T[]) => {
        this.elements = data        
        this.dataSourceManipulation()        
      }
    )
  }

  view(selector) {
    this.service.view(selector).subscribe(
      (data: T) => {
        this.element = data
        console.log(this.element)
      }
    )
  }

  new(data, route_return: string){
    this.element = data;
    this.service.save(this.element).subscribe(
      (data: T) => {
        this.router.navigate([route_return])
      }
    )
  }

  edit(data, id: string, route_return: string){
    this.element = data;
    this.service.save(this.element, id).subscribe(
      (data: T) => {
        this.router.navigate([route_return])
      }
    )
  }  

  protected loadRegister(id?: string){
    this.service.view(id).subscribe(
      (data: T) => {            
        this.element = data
        this.form.patchValue(
          this.populateElement(data)        
        )
      }
    )
  }  

  protected populateElement(data : {}){
    return { 
      ...data    
    }
  }  

}
