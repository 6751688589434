import { Component, OnInit } from '@angular/core';
import { Package } from 'src/app/shared/model/package.model';
import { ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { GrupoService } from 'src/app/shared/service/grupo.service';

@Component({
  selector: 'app-grupo-package-list',
  templateUrl: './grupo-package-list.component.html',
  styleUrls: ['./grupo-package-list.component.css'],
  providers: [GrupoService]
})
export class GrupoPackageListComponent implements OnInit {

  public elements: Package[] = []
  private originalElements: Package[] = []
  private grupoSelector: string;
  public packageName: string;
  public contentLoading: boolean = false;

  constructor(protected service: GrupoService, private route: ActivatedRoute, private _snackBar: MatSnackBar) {     
  } 
  
  ngOnInit() {
    this.route.params.subscribe((parametros: Params) => {
      if ('selector' in parametros) {
        this.list(parametros.selector)
      }
    })    
  }

  list(selector: string) {
    this.contentLoading = true
    this.grupoSelector = selector;
    this.service.listPackages(selector).subscribe(
      (data: Package[]) => {
        this.originalElements = this.elements = data
        this.search(this.packageName)
        this.contentLoading = false

      }
    )
  }

  delete(element: Package) {
    this.service.deletePackage(element.selector).subscribe(
      () => {
        this.list(this.grupoSelector);
      }
    )  
  }

  addPackage(){
    if(this.packageName === "") {
      return false;
    }

    let exists = this.originalElements.filter((obj)  =>
      obj.nome == this.packageName
    );

    if(exists.length > 0){
      this._snackBar.open("Valor já consta na lista!", "", { duration: 3000, panelClass: ['app-alert'] })                 
      return false;
    }    

    let data: any = {};
    data.nome = this.packageName;
    data.grupo = this.grupoSelector;
  
    this.service.addPackage(data).subscribe(
      () => {
        this.list(this.grupoSelector)
      },
      (httpError: HttpErrorResponse) => {
        if(httpError.status === 400){
          this._snackBar.open("Valor inválido!", "", { duration: 3000, panelClass: ['app-alert'] })                 
        }                
      }      
    )     
  }

  search(value: string){
    if(value === "" || value === undefined){
      this.elements = this.originalElements
    }else{
      this.elements = this.originalElements.filter( element => -1 !== element.nome.indexOf(value));    
    }
  }

  limpar(){
    this.packageName = ""
    this.search(this.packageName)
  }

}
