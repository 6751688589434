import { Component, OnInit, Injectable } from '@angular/core';
import { Cliente } from 'src/app/shared/model/cliente.model';
import { ClienteService } from 'src/app/shared/service/cliente.service';
import { GenericComponent } from 'src/app/components/generic/generic.component';
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
  selector: 'app-cliente-list',
  templateUrl: './cliente-list.component.html',
  styleUrls: ['./cliente-list.component.css'],
  providers: [ClienteService],
})

@Injectable()
export class ClienteListComponent extends GenericComponent<Cliente> implements OnInit {

  protected getFilteredColumns(data: any): string[] {    
    return [data.nome];
  }

  public getDisplayedColumns(){
    return ['nome', 'details']
  }



  constructor(protected service: ClienteService, protected authService: AuthService) {
    super(authService)
  }
}