import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable()
export abstract class GenericService<T> {

    private token = localStorage.getItem('token');
    protected urlList;
    
    public constructor(protected http: HttpClient, private entity) {}    

    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.token
        })
    }

    public list(): Observable<T[]> {
        return this.http.get<T[]>(this.urlList['list'], this.httpOptions)
            .pipe( map(elements => elements.map( (element) => Object.assign(new this.entity, element) ) ) )
    }

    public view(id): Observable<T> {
        return this.http.get<T>(this.urlList['view']+'/'+ id, this.httpOptions)
            .pipe( map(element => Object.assign(new this.entity, element) ) )
    }

    public save(element: T, id?: string): Observable<T> {
        if(!id){
            return this.http.post<T>(this.urlList['new'], JSON.stringify(element), this.httpOptions);
        } else {
            return this.http.put<T>(this.urlList['edit']+'/'+ id, JSON.stringify(element), this.httpOptions);
        }
    }

}
