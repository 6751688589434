import { Cliente } from './cliente.model'
import { Contrato } from './contrato.model';
import { Grupo } from './grupo.model';

export class Usuario {
    selector: string
    nome: string
    username: string
    password: string
    cliente?: Cliente
    contrato?: Contrato
    grupo?: Grupo
}