import { Component, OnInit, Injectable, ChangeDetectorRef } from '@angular/core';
import { Solicitacao, SOLICITACAO_STATUS } from 'src/app/shared/model/solicitacao.model';
import { SolicitacaoService } from 'src/app/shared/service/solicitacao.service';
import { GenericComponent } from 'src/app/components/generic/generic.component';
import { AuthService } from 'src/app/shared/service/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-solicitacao-list',
  templateUrl: './solicitacao-list.component.html',
  styleUrls: ['./solicitacao-list.component.css'],
  providers: [SolicitacaoService],
})

@Injectable()
export class SolicitacaoListComponent extends GenericComponent<Solicitacao> {

  public enumSolicitacaoStatus = SOLICITACAO_STATUS;

  constructor(protected service: SolicitacaoService, protected authService: AuthService, private _snackBar: MatSnackBar) {
    super(authService)
  }

  protected getFilteredColumns(data: any): string[] {    
    return [data.data_hora_solicitacao, data.data_hora_status, data.statusDescricao, data.dispositivo.identificador];
  }

  public getDisplayedColumns(): string[]{
    return ['data_hora_solicitacao', 'dispositivo', 'status_descricao', 'data_hora_status', 'usuario', 'details']
  }

  aprovar(element: Solicitacao) {
    this.service.aprovar(element.selector).subscribe(
      (data: Solicitacao) => {
        let indexElement = this.dataSource.data.indexOf(element)        
        this.dataSource.data[indexElement] = Object.assign(this.dataSource.data[indexElement], data);
      },
      (httpError: HttpErrorResponse) =>{
        if(httpError.error == "Solicitacao - Error - Limite do contrato excedido."){
          this._snackBar.open("Limite de uso do contrato excedido!", "", { duration: 5000, panelClass: ['app-alert'] })                 
        }
      }            
    );
  }

  recusar(element: Solicitacao) {
    this.service.recusar(element.selector).subscribe(
      (data: Solicitacao) => {
        let indexElement = this.dataSource.data.indexOf(element)        
        this.dataSource.data[indexElement] = Object.assign(this.dataSource.data[indexElement], data);
      }
    );
  }  
}