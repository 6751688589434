import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { Dispositivo } from '../model/dispositivo.model';
import { urlList } from '../model/remote_url';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Package } from '../model/package.model';


@Injectable()
export class DispositivoService extends GenericService<Dispositivo>{
    
    public constructor(protected http: HttpClient){            
        super(http, Dispositivo)
        this.urlList = urlList['dispositivo']
    }        

    public ativar(id: string) {
        return this.http.put<Dispositivo>(this.urlList['ativar']+'/'+ id +'/ativar', [], this.httpOptions)
        .pipe( map(element => Object.assign(new Dispositivo, element) ) );
    }    

    public desativar(id: string) {
        return this.http.put<Dispositivo>(this.urlList['desativar']+'/'+ id +'/desativar', [], this.httpOptions)
        .pipe( map(element => Object.assign(new Dispositivo, element) ) );
    }
    
    public bloquear(mensagem: string, id: string) {
        return this.http.put<Dispositivo>(this.urlList['bloquear']+'/'+ id +'/bloquear/'+mensagem, [], this.httpOptions)
        .pipe(map(element => Object.assign(new Dispositivo, element)));
    }
    
    public desbloquear(id: string) {
        return this.http.put<Dispositivo>(this.urlList['desbloquear']+'/'+ id +'/desbloquear', [], this.httpOptions)
        .pipe(map(element => Object.assign(new Dispositivo, element)));
    }
    
    public save(element: Dispositivo, id?: string): Observable<Dispositivo> {
        return this.http.put<Dispositivo>(this.urlList['alterar_senha']+'/'+ id +'/alterar_senha', JSON.stringify(element), this.httpOptions);
    } 

    public listPackages(selector: string){
        return this.http.get<Package[]>(this.urlList['list_package']+'/'+selector+'/package', this.httpOptions)
            .pipe( map(elements => elements.map( (element) => Object.assign(new Package, element) ) ) )                
    }

    public deletePackage(selector: string){
        return this.http.delete<Package[]>(this.urlList['delete_package']+'/'+selector, this.httpOptions)
    }   
    
    public addPackage(element: any){
        return this.http.post<Package>(this.urlList['add_package'], JSON.stringify(element), this.httpOptions);                
    }    
}