import { Component, OnInit, Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DispositivoService } from '../../../shared/service/dispositivo.service';
import { Dispositivo } from '../../../shared/model/dispositivo.model';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { GenericComponent } from 'src/app/components/generic/generic.component';
import { AuthService } from 'src/app/shared/service/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dispositivo-form',
  templateUrl: './dispositivo-form.component.html',
  styleUrls: ['./dispositivo-form.component.css'],
  providers: [DispositivoService],
})

@Injectable()
export class DispositivoFormComponent extends GenericComponent<Dispositivo> {

  constructor(protected service: DispositivoService,
    protected router: Router,
    private formBuilder: FormBuilder,
    protected authService: AuthService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar
    ) { 
    super(authService)
  }

  public form = this.formBuilder.group({
    senha: ['', Validators.required]
  })

  ngOnInit() {
    
    this.route.params.subscribe((parametros: Params) => {
      if ('selector' in parametros) {
        this.loadRegister(parametros.selector)
      }
    })    
  }

  save(data) {
    if (this.form.invalid) {
      return false;
    }

    if(true === isNaN(data.senha)){
      this._snackBar.open("Valor inválido", "", { duration: 3000, panelClass: ['app-alert'] })       
      return false;
    }
    this._snackBar.dismiss();
    
    super.edit(data, this.element.selector, '/admin/dispositivo')          
  }
}
