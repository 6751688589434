import { Component, OnInit, Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GrupoService } from '../../../shared/service/grupo.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { GenericComponent } from 'src/app/components/generic/generic.component';
import { AuthService } from 'src/app/shared/service/auth.service';
import { Grupo } from 'src/app/shared/model/grupo.model';

@Component({
  selector: 'app-grupo-form',
  templateUrl: './grupo-form.component.html',
  styleUrls: ['./grupo-form.component.css'],
  providers: [GrupoService],
})

@Injectable()
export class GrupoFormComponent extends GenericComponent<Grupo> {

  constructor(protected service: GrupoService,
    protected router: Router,
    private formBuilder: FormBuilder,
    protected authService: AuthService,
    private route: ActivatedRoute
    ) { 
    super(authService)
  }

  public form = this.formBuilder.group({
    nome: ['', Validators.required]
  })

  ngOnInit() {
    
    this.route.params.subscribe((parametros: Params) => {
      if ('selector' in parametros) {
        this.loadRegister(parametros.selector)
      }
    })    
  }

  save(data) {
    if (this.form.invalid) {
      return false;
    }
    if(!this.element){
      super.new(data, '/admin/grupo')
    } else {
      super.edit(data, this.element.selector, '/admin/grupo')      
    }
  }
}
