import { Component, Injectable } from '@angular/core';
import { Solicitacao } from 'src/app/shared/model/solicitacao.model';
import { SolicitacaoService } from 'src/app/shared/service/solicitacao.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { GenericComponent } from 'src/app/components/generic/generic.component';

@Component({
  selector: 'app-solicitacao-aprovada-home',
  templateUrl: './solicitacao-aprovada-home.component.html',
  styleUrls: ['./solicitacao-aprovada-home.component.css'],
})
export class SolicitacaoAprovadaHomeComponent extends GenericComponent<Solicitacao> {

  constructor(protected service: SolicitacaoService, protected authService: AuthService) {
    super(authService)
 
  }

  public getDisplayedColumns(): string[]{
    return ['data_hora_status', 'dispositivo', 'marca_modelo', 'usuario']
  }  

  ngOnInit() {
    super.ngOnInit();

    this.service.aprovarEvent.subscribe(x => {
      this.list()
    });       
  }

  list() {
    this.service.listAprovadas().subscribe(
      (data: Solicitacao[]) => {
        this.elements = data;
        this.dataSourceManipulation()        
      }
    )
  }
}
