import { Component, OnInit, Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ContratoService } from '../../../shared/service/contrato.service';
import { Contrato } from '../../../shared/model/contrato.model';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { GenericComponent } from 'src/app/components/generic/generic.component';
import { AuthService } from 'src/app/shared/service/auth.service';
import { ClienteService } from 'src/app/shared/service/cliente.service';
import { Cliente } from 'src/app/shared/model/cliente.model';

@Component({
  selector: 'app-contrato-form',
  templateUrl: './contrato-form.component.html',
  styleUrls: ['./contrato-form.component.css'],
})

@Injectable()
export class ContratoFormComponent extends GenericComponent<Contrato> {

  public clienteOptions: Cliente[];

  constructor(protected service: ContratoService,
    protected router: Router,
    private formBuilder: FormBuilder,
    protected authService: AuthService,
    private route: ActivatedRoute,
    private clienteService: ClienteService) {

    super(authService)
  }

  public form = this.formBuilder.group({
    codigo: ['', Validators.required],
    cliente: ['', Validators.required],
    limite: ['', Validators.pattern("^[0-9]*$")]
  })


  ngOnInit() {
    this.clienteService.list().subscribe(
      (data: Cliente[]) => this.clienteOptions = data
    );

    this.route.params.subscribe((parametros: Params) => {
      if ('selector' in parametros) {
        this.loadRegister(parametros.selector)
      }
    })
  }  

  save(data) {
    if (this.form.invalid) {
      return false;
    }
    
    if(!this.element){
      super.new(data, '/admin/contrato')
    } else {
      super.edit(data, this.element.selector, '/admin/contrato')      
    }
  }

  protected populateElement(data : {}){
    return { 
      ...data,
      cliente: this.element.cliente.selector
    }
  }
}
