import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as jwt_decode from "jwt-decode";
import { urlList } from '../model/remote_url';
import { Router } from '@angular/router';


@Injectable()
export class AuthService {

    private token = localStorage.getItem('token');
    private urlList;

    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    public constructor(protected http: HttpClient, private router: Router){            
        this.urlList = urlList['auth']
    }    

    public login(username: string, password: string): Observable<any> {
        return this.http.post(this.urlList['login'], `{"username":"${username}","password":"${password}"}`, this.httpOptions);
    }

    get currentUserValue() {
        if(true === this.isExpired(this.token)){
            this.logout();
        }
        return jwt_decode(this.token);        
    }

    isExpired (token) {
        if (token && jwt_decode(token)) {
          const expiry = jwt_decode(token).exp;
          const now = new Date();
          return now.getTime() > expiry * 1000;
        }
        return false;    
    }

    isGranted(roles: string[]) {
        let retorno: boolean = false;        

        for(let index in this.currentUserValue.roles){
            let role =  this.currentUserValue.roles[index]
            if (roles && roles.indexOf(role) !== -1) {
                retorno =  true;
                break; // break forEach
            }                                    
        }

        return retorno;
    }

    logout(){
        localStorage.setItem('token', null)    
        window.location.replace('/login');
    }
}