import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Contrato } from '../model/contrato.model';
import { GenericService } from './generic.service';
import { urlList } from '../model/remote_url';
import { Package } from '../model/package.model';
import { map } from 'rxjs/operators';
import { DispositivoPreAprovado } from '../model/dispositivoPreAprovado.model';


@Injectable()
export class ContratoService extends GenericService<Contrato>{
    
    public constructor(protected http: HttpClient){            
        super(http, Contrato)
        this.urlList = urlList['contrato']
    }

    public listPackages(selector: string){
        return this.http.get<Package[]>(this.urlList['list_package']+'/'+selector+'/package', this.httpOptions)
            .pipe( map(elements => elements.map( (element) => Object.assign(new Package, element) ) ) )                
    }

    public deletePackage(selector: string){
        return this.http.delete<Package[]>(this.urlList['delete_package']+'/'+selector, this.httpOptions)
    }   
    
    public addPackage(element: any){
        return this.http.post<Package>(this.urlList['add_package'], JSON.stringify(element), this.httpOptions);                
    }

    public listDispositivosPreAprovados(selector: string){
        return this.http.get<DispositivoPreAprovado[]>(this.urlList['list_dispositivo']+'/'+selector+'/dispositivo_pre_aprovado', this.httpOptions)
            .pipe( map(elements => elements.map( (element) => Object.assign(new Package, element) ) ) )                
    }

    public deleteDispositivoPreAprovado(selector: string){
        return this.http.delete<DispositivoPreAprovado[]>(this.urlList['delete_dispositivo']+'/'+selector, this.httpOptions)
    }   
    
    public addDispositivoPreAprovado(element: any){
        return this.http.post<DispositivoPreAprovado>(this.urlList['add_dispositivo'], JSON.stringify(element), this.httpOptions);                
    }    
}