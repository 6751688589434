import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cliente } from '../model/cliente.model';
import { GenericService } from './generic.service';
import { urlList } from '../model/remote_url';


@Injectable()
export class ClienteService extends GenericService<Cliente>{
    
    public constructor(protected http: HttpClient){            
        super(http, Cliente)
        this.urlList = urlList['cliente']
    }        
}