import { Component, Injectable } from '@angular/core';
import { GenericComponent } from 'src/app/components/generic/generic.component';
import { UsuarioService } from 'src/app/shared/service/usuario.service';
import { Usuario } from 'src/app/shared/model/usuario.model';
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
  selector: 'app-usuario-list',
  templateUrl: './usuario-list.component.html',
  styleUrls: ['./usuario-list.component.css'],
  providers: [UsuarioService]
})

@Injectable()
export class UsuarioListComponent extends GenericComponent<Usuario>  {

  constructor(protected service: UsuarioService, protected authService: AuthService) { 
    super(authService)
  }

  protected getFilteredColumns(data: any): string[] {    
    let listColumns = [];
    listColumns.push(data.username, data.nome);
    if(data.cliente && this.isGranted(['ROLE_MASTER'])){
      listColumns.push(data.cliente.nome)
    }
    if(data.contrato && this.isGranted(['ROLE_MASTER'])){
      listColumns.push(data.contrato.codigo)
    }    
    return listColumns;
  }

  public getDisplayedColumns(){
    if(this.isGranted(['ROLE_MASTER'])){
      return ['username', 'nome', 'contrato', 'cliente', 'details']
    }

    return ['username', 'nome']
  }  

}
