import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpClient,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

enum ErrorsType {
  ACESS_DENIED = 401
}

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(protected http: HttpClient, private _snackBar: MatSnackBar, private router: Router,) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => {},
        (err) => {
          if ( err.status === ErrorsType.ACESS_DENIED ) {
            this._snackBar.open("Acesso Negado.", "", { duration: 5000, panelClass: ['app-alert'] });
            localStorage.setItem('token', null)    ;
            this.router.navigate(['/login']);
          }
        }
      )
    );
  }
}
