import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { Solicitacao } from '../model/solicitacao.model';
import { GenericService } from './generic.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { urlList } from '../model/remote_url';



@Injectable()
export class SolicitacaoService extends GenericService<Solicitacao>{

    @Output() aprovarEvent: EventEmitter<boolean> = new EventEmitter();

    public constructor(protected http: HttpClient){            
        super(http, Solicitacao)
        this.urlList = urlList['solicitacao']
    }        
    
    public listPendentes(): Observable<Solicitacao[]> {        
        return this.http.get<Solicitacao[]>(this.urlList['list_pendente'], this.httpOptions)
            .pipe( map(elements => elements.map( (element) => Object.assign(new Solicitacao, element) ) ) )        
    }

    public listAprovadas(): Observable<Solicitacao[]> {        
        return this.http.get<Solicitacao[]>(this.urlList['list_aprovada_ultima'], this.httpOptions)
            .pipe( map(elements => elements.map( (element) => Object.assign(new Solicitacao, element) ) ) )        
    }

    public aprovar(id: string){
        return this.http.put<Solicitacao>(this.urlList['aprovar']+'/'+ id +'/aprovar', [], this.httpOptions)
        .pipe( map(element => Object.assign(new Solicitacao, element) ) );
    }

    public recusar(id: string){
        return this.http.put<Solicitacao>(this.urlList['recusar']+'/'+ id +'/recusar', [], this.httpOptions)
        .pipe( map(element => Object.assign(new Solicitacao, element) ) );
    }    
}