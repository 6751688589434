import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';

//Custom
import { HttpClientModule } from '@angular/common/http';
import { ClienteComponent } from './pages/cliente/cliente.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

//Materials
import {A11yModule} from '@angular/cdk/a11y';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import { TopoComponent } from './pages/topo/topo.component';
import { ClienteFormComponent } from './pages/cliente/cliente-form/cliente-form.component';
import { AdminComponent } from './pages/admin/admin.component';
import { ClienteListComponent } from './pages/cliente/cliente-list/cliente-list.component';
import { AuthService } from './shared/service/auth.service';
import { ContratoModule } from './pages/contrato/contrato.module';
import { SolicitacaoComponent } from './pages/solicitacao/solicitacao.component';
import { SolicitacaoListComponent } from './pages/solicitacao/solicitacao-list/solicitacao-list.component';
import { DispositivoComponent } from './pages/dispositivo/dispositivo.component';
import { DispositivoListComponent } from './pages/dispositivo/dispositivo-list/dispositivo-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HomeComponent } from './pages/home/home.component';
import { SolicitacaoPendenteHomeComponent } from './pages/home/solicitacao-pendente-home/solicitacao-pendente-home.component';
import { BaseComponent } from './components/base/base.component';
import { GenericComponent } from './components/generic/generic.component';
import { SolicitacaoAprovadaHomeComponent } from './pages/home/solicitacao-aprovada-home/solicitacao-aprovada-home.component';
import { SolicitacaoService } from './shared/service/solicitacao.service';
import { UsuarioComponent } from './pages/usuario/usuario.component';
import { UsuarioListComponent } from './pages/usuario/usuario-list/usuario-list.component';
import { UsuarioFormComponent } from './pages/usuario/usuario-form/usuario-form.component';
import { ContratoResumoComponent } from './pages/home/contrato-resumo/contrato-resumo.component';
import { DispositivoFormComponent } from './pages/dispositivo/dispositivo-form/dispositivo-form.component';
import { DispositivoPackageListComponent } from './pages/dispositivo/dispositivo-package-list/dispositivo-package-list.component';
import { DialogMensagemBloqueio } from './pages/dialog/dialog-mensagem-bloqueio.component';
import { ContraSenhaComponent } from './pages/contrasenha/contrasenha.component';
import { ContraSenhaFormComponent } from './pages/contrasenha/contrasenha-form/contrasenha-form.component';
import { InterceptorModule } from './shared/interceptor/errorInterceptor.module';
import { GrupoComponent } from './pages/grupo/grupo.component';
import { GrupoListComponent } from './pages/grupo/grupo-list/grupo-list.component';
import { GrupoFormComponent } from './pages/grupo/grupo-form/grupo-form.component';
import { GrupoPackageListComponent } from './pages/grupo/grupo-package-list/grupo-package-list.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ClienteComponent,
    TopoComponent,
    ClienteFormComponent,
    AdminComponent,
    ClienteListComponent,
    SolicitacaoComponent,
    SolicitacaoListComponent,
    DispositivoComponent,
    DispositivoListComponent,
    HomeComponent,
    SolicitacaoPendenteHomeComponent,
    BaseComponent,
    GenericComponent,
    SolicitacaoAprovadaHomeComponent,
    UsuarioComponent,
    UsuarioListComponent,
    UsuarioFormComponent,
    ContratoResumoComponent,
    DispositivoFormComponent,
    DispositivoPackageListComponent,
    DialogMensagemBloqueio,
    ContraSenhaComponent,    
    ContraSenhaFormComponent,
    GrupoComponent,
    GrupoListComponent,
    GrupoFormComponent,
    GrupoPackageListComponent
  ],
  entryComponents: [DialogMensagemBloqueio],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,    
    FlexLayoutModule,    
    ContratoModule,
    FontAwesomeModule,

    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule,
    InterceptorModule
  ],
  providers: [AuthService, SolicitacaoService],
  bootstrap: [AppComponent]
})
export class AppModule { }
