import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { urlList } from '../model/remote_url';
import { Grupo } from '../model/grupo.model';
import { Package } from '../model/package.model';
import { map } from 'rxjs/operators';

@Injectable()
export class GrupoService extends GenericService<Grupo>{
    
    public constructor(protected http: HttpClient){            
        super(http, Grupo)
        this.urlList = urlList['grupo']
    }

    public listPackages(selector: string){
        return this.http.get<Package[]>(this.urlList['list_package']+'/'+selector+'/package', this.httpOptions)
            .pipe( map(elements => elements.map( (element) => Object.assign(new Package, element) ) ) )                
    }

    public deletePackage(selector: string){
        return this.http.delete<Package[]>(this.urlList['delete_package']+'/'+selector, this.httpOptions)
    }   
    
    public addPackage(element: any){
        return this.http.post<Package>(this.urlList['add_package'], JSON.stringify(element), this.httpOptions);                
    }
}