import { Component, OnInit } from '@angular/core';
import { DispositivoPreAprovado } from 'src/app/shared/model/dispositivoPreAprovado.model';
import { ContratoService } from 'src/app/shared/service/contrato.service';
import { ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-contrato-dispositivo-pre-aprovado-list',
  templateUrl: './contrato-dispositivo-pre-aprovado-list.component.html',
  styleUrls: ['./contrato-dispositivo-pre-aprovado-list.component.css']
})
export class ContratoDispositivoPreAprovadoListComponent implements OnInit {

  public elements: DispositivoPreAprovado[] = []
  private originalElements: DispositivoPreAprovado[] = []
  private contratoSelector: string;
  public dispositivoIdentificador: string;
  public contentLoading: boolean = false;

  constructor(protected service: ContratoService, private route: ActivatedRoute,  private _snackBar: MatSnackBar) {     
  } 
  
  ngOnInit() {
    this.route.params.subscribe((parametros: Params) => {
      if ('selector' in parametros) {
        this.list(parametros.selector)
      }
    })    
  }

  list(selector: string) {
    this.contentLoading = true
    this.contratoSelector = selector;
    this.service.listDispositivosPreAprovados(selector).subscribe(
      (data: DispositivoPreAprovado[]) => {
        this.originalElements = this.elements = data
        this.search(this.dispositivoIdentificador)
        this.contentLoading = false
      }
    )
  }

  delete(element: DispositivoPreAprovado) {
    this.service.deleteDispositivoPreAprovado(element.selector).subscribe(
      () => {
        this.list(this.contratoSelector);
      }
    )  
  }

  addDispositivo(){
    if(this.dispositivoIdentificador === "") {
      return false;
    }

    let exists = this.originalElements.filter((obj)  =>
      obj.identificador == this.dispositivoIdentificador
    );

    if(exists.length > 0){
      this._snackBar.open("Valor já consta na lista!", "", { duration: 3000, panelClass: ['app-alert'] })                 
      return false;
    }
     
    let data: any = {};
    data.identificador = this.dispositivoIdentificador;
    data.contrato = this.contratoSelector;
  
    this.service.addDispositivoPreAprovado(data).subscribe(
      () => {
        this.list(this.contratoSelector)
      },
      (httpError: HttpErrorResponse) => {
        if(httpError.status === 400){
          this._snackBar.open("Valor inválido!", "", { duration: 3000, panelClass: ['app-alert'] })                 
        }                
      }
    )     
  }

  search(value: string){
    if(value === "" || value === undefined){
      this.elements = this.originalElements
    }else{
      this.elements = this.originalElements.filter( element => -1 !== element.identificador.indexOf(value));    
    }
  }

  limpar(){
    this.dispositivoIdentificador = ""
    this.search(this.dispositivoIdentificador)
  }

}
