import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ClienteComponent } from './pages/cliente/cliente.component';
import { ClienteFormComponent } from './pages/cliente/cliente-form/cliente-form.component';
import { ContratoComponent } from './pages/contrato/contrato.component';
import { AdminComponent } from './pages/admin/admin.component';
import { ClienteListComponent } from './pages/cliente/cliente-list/cliente-list.component';
import { ContratoListComponent } from './pages/contrato/contrato-list/contrato-list.component';
import { AuthGuard } from './guard/auth.guard';
import { Role } from './shared/model/role';
import { ContratoFormComponent } from './pages/contrato/contrato-form/contrato-form.component';
import { SolicitacaoComponent } from './pages/solicitacao/solicitacao.component';
import { SolicitacaoListComponent } from './pages/solicitacao/solicitacao-list/solicitacao-list.component';
import { DispositivoComponent } from './pages/dispositivo/dispositivo.component';
import { DispositivoListComponent } from './pages/dispositivo/dispositivo-list/dispositivo-list.component';
import { HomeComponent } from './pages/home/home.component';
import { UsuarioComponent } from './pages/usuario/usuario.component';
import { UsuarioListComponent } from './pages/usuario/usuario-list/usuario-list.component';
import { UsuarioFormComponent } from './pages/usuario/usuario-form/usuario-form.component';
import { ContratoPackageListComponent } from './pages/contrato/contrato-package-list/contrato-package-list.component';
import { ContratoDispositivoPreAprovadoListComponent } from './pages/contrato/contrato-dispositivo-pre-aprovado-list/contrato-dispositivo-pre-aprovado-list.component';
import { DispositivoFormComponent } from './pages/dispositivo/dispositivo-form/dispositivo-form.component';
import { DispositivoPackageListComponent } from './pages/dispositivo/dispositivo-package-list/dispositivo-package-list.component';
import { ContraSenhaComponent } from './pages/contrasenha/contrasenha.component';
import { ContraSenhaFormComponent } from './pages/contrasenha/contrasenha-form/contrasenha-form.component';
import { GrupoComponent } from './pages/grupo/grupo.component';
import { GrupoListComponent } from './pages/grupo/grupo-list/grupo-list.component';
import { GrupoFormComponent } from './pages/grupo/grupo-form/grupo-form.component';
import { GrupoPackageListComponent } from './pages/grupo/grupo-package-list/grupo-package-list.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  {
    path: 'admin', component: AdminComponent,

    children: [
      { path: 'home', component: HomeComponent },
      {
        path: 'cliente', component: ClienteComponent,
        canActivate: [AuthGuard], data: { roles: [Role.Master] },
        children: [
          { path: '', component: ClienteListComponent },
          { path: 'new', component: ClienteFormComponent, },
          { path: 'edit/:selector', component: ClienteFormComponent },
        ]
      },
      {
        path: 'contrato', component: ContratoComponent,
        canActivate: [AuthGuard], data: { roles: [Role.Admin] },
        children: [
          { path: '', component: ContratoListComponent },
          {
            path: 'new', component: ContratoFormComponent,
            canActivate: [AuthGuard], data: { roles: [Role.Master] },
          },
          { path: 'edit/:selector', component: ContratoFormComponent },
          { path: 'package/:selector', component: ContratoPackageListComponent },
          { path: 'dispositivo_pre_aprovado/:selector', component: ContratoDispositivoPreAprovadoListComponent },

        ]
      },
      {
        path: 'solicitacao', component: SolicitacaoComponent,
        canActivate: [AuthGuard], data: { roles: [Role.Operador] },
        children: [
          { path: '', component: SolicitacaoListComponent },
        ]
      },
      {
        path: 'dispositivo', component: DispositivoComponent,
        canActivate: [AuthGuard], data: { roles: [Role.Operador] },
        children: [
          { path: '', component: DispositivoListComponent },
          { path: 'edit/:selector', component: DispositivoFormComponent},
          { path: 'package/:selector', component: DispositivoPackageListComponent },
        ]
      },
      {
        path: 'usuario', component: UsuarioComponent,
        canActivate: [AuthGuard], data: { roles: [Role.Operador] },
        children: [
          { path: '', component: UsuarioListComponent, 
          canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
          { path: 'new', component: UsuarioFormComponent,
          canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
          { path: 'edit/:selector', component: UsuarioFormComponent,
          canActivate: [AuthGuard], data: { roles: [Role.Operador] } },
        ]
      },
      {
        path: 'grupo', component: GrupoComponent,
        canActivate: [AuthGuard], data: { roles: [Role.Operador] },
        children: [
          { path: '', component: GrupoListComponent, 
          canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
          { path: 'new', component: GrupoFormComponent,
          canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
          { path: 'edit/:selector', component: GrupoFormComponent,
          canActivate: [AuthGuard], data: { roles: [Role.Operador] } },
          { path: 'package/:selector', component: GrupoPackageListComponent }
        ]
      },
      {
        path: 'contra-senha', component: ContraSenhaComponent,
        canActivate: [AuthGuard], data: { roles: [Role.Operador] },
        children: [
          { path: '', component: ContraSenhaFormComponent }
        ]
      },
    ]
  },
  { path: 'login', component: LoginComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
