import { Component, OnInit, Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ClienteService } from '../../../shared/service/cliente.service';
import { Cliente } from '../../../shared/model/cliente.model';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { GenericComponent } from 'src/app/components/generic/generic.component';
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
  selector: 'app-cliente-form',
  templateUrl: './cliente-form.component.html',
  styleUrls: ['./cliente-form.component.css'],
  providers: [ClienteService],
})

@Injectable()
export class ClienteFormComponent extends GenericComponent<Cliente> {

  constructor(protected service: ClienteService,
    protected router: Router,
    private formBuilder: FormBuilder,
    protected authService: AuthService,
    private route: ActivatedRoute
    ) { 
    super(authService)
  }

  public form = this.formBuilder.group({
    nome: ['', Validators.required]
  })

  ngOnInit() {
    
    this.route.params.subscribe((parametros: Params) => {
      if ('selector' in parametros) {
        this.loadRegister(parametros.selector)
      }
    })    
  }

  save(data) {
    if (this.form.invalid) {
      return false;
    }
    if(!this.element){
      super.new(data, '/admin/cliente')
    } else {
      super.edit(data, this.element.selector, '/admin/cliente')      
    }
  }
}
