import { Component, OnInit } from '@angular/core';
import { GenericComponent } from 'src/app/components/generic/generic.component';
import { Contrato } from 'src/app/shared/model/contrato.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { ContratoService } from 'src/app/shared/service/contrato.service';
import { SolicitacaoService } from 'src/app/shared/service/solicitacao.service';

@Component({
  selector: 'app-contrato-resumo',
  templateUrl: './contrato-resumo.component.html',
  styleUrls: ['./contrato-resumo.component.css']
})
export class ContratoResumoComponent  extends GenericComponent<Contrato>  {

  constructor(protected service: ContratoService, protected authService: AuthService, private solicitacaoService: SolicitacaoService) { 
    super(authService)
  }  

  public getDisplayedColumns(): string[]{
    return ['codigo', 'limite', 'quantidade_dispositivo_ativo', 'percentual_uso']
  }  

  ngOnInit() {
    super.ngOnInit();

    this.solicitacaoService.aprovarEvent.subscribe(x => {
      this.list()
    });       
  }  
}

