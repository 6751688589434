import { Dispositivo } from './dispositivo.model';
import { Usuario } from './usuario.model';

export enum SOLICITACAO_STATUS {
    Pendente = '0',
    Aprovada = '1',
    PreAprovada = '2',
    Recusada = '9',
}

const status_list = { 
    "0": "Pendente",
    "1": "Aprovada", 
    "2": "Pré-aprovada",
    "9": "Recusada",
}

export class Solicitacao {
    selector: string
    data_hora_solicitacao: string
    data_hora_status: string
    status: number
    dispositivo: Dispositivo   
    usuario_status: Usuario 
    
    get statusDescricao() {
        return status_list[this.status]        
    }
}

