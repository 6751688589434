import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Usuario } from '../model/usuario.model';
import { GenericService } from './generic.service';
import { urlList } from '../model/remote_url';


@Injectable()
export class UsuarioService extends GenericService<Usuario>{
    
    public constructor(protected http: HttpClient){            
        super(http, Usuario)
        this.urlList = urlList['usuario']
    }
}