import { Component, OnInit, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/service/auth.service';
import { BaseComponent } from 'src/app/components/base/base.component';
import { faHome, faUser, faUserTie, faFileContract, faTabletAlt, faUnlockAlt, faSignOutAlt, faAddressCard, faKey  } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-topo',
  templateUrl: './topo.component.html',
  styleUrls: ['./topo.component.css'],
})

@Injectable()
export class TopoComponent extends BaseComponent implements OnInit {
  faHome = faHome;
  faCliente = faUserTie;
  faContrato = faFileContract;
  faSolicitacao = faUnlockAlt;
  faDispositivo = faTabletAlt;
  faUsuario = faUser;
  faUserProfile = faAddressCard;  
  faLogout = faSignOutAlt; 
  faContraSenha = faKey;
  username: string;
  selector: string;

  constructor(private router: Router, protected authService: AuthService){
    super(authService)
  }

  ngOnInit() {    
    this.selector = this.authService.currentUserValue.selector;
    this.username = this.authService.currentUserValue.username;
  }

  logout(){    
    this.authService.logout();
  }
  
}
