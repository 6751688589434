import { Component, Injectable } from '@angular/core';
import { Solicitacao, SOLICITACAO_STATUS } from 'src/app/shared/model/solicitacao.model';
import { SolicitacaoService } from 'src/app/shared/service/solicitacao.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { GenericComponent } from 'src/app/components/generic/generic.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-solicitacao-pendente-home',
  templateUrl: './solicitacao-pendente-home.component.html',
  styleUrls: ['./solicitacao-pendente-home.component.css']
})

@Injectable()
export class SolicitacaoPendenteHomeComponent extends GenericComponent<Solicitacao> {
  
  public enumSolicitacaoStatus = SOLICITACAO_STATUS;
  public solicitacaoesQnt: number;

  constructor(protected service: SolicitacaoService, protected authService: AuthService, private _snackBar: MatSnackBar) {
    super(authService)
 
  }

  public getDisplayedColumns(): string[]{
    return  ['data_hora_solicitacao', 'dispositivo' , 'dispositivo_informacao', 'usuario', 'details']
  }  


  list() {
    this.service.listPendentes().subscribe(
      (data: Solicitacao[]) => {
        this.solicitacaoesQnt = data.length;
        this.elements = data.slice(0,5);
        this.dataSourceManipulation()        
      }
    )
  }

  aprovar(element: Solicitacao) {
    this.service.aprovar(element.selector).subscribe(
      (data: Solicitacao) => {
        this.list()
        this.service.aprovarEvent.emit(true)
        this._snackBar.open("Solicitação Aprovada com sucesso!", "", { duration: 1000, panelClass: ['app-primary'] })
      },
      (httpError: HttpErrorResponse) =>{
        if(httpError.error == "Solicitacao - Error - Limite do contrato excedido."){
          this._snackBar.open("Limite de uso do contrato excedido!", "", { duration: 5000, panelClass: ['app-alert'] })                 
        }
      }
    );
  }

  recusar(element: Solicitacao) {
    this.service.recusar(element.selector).subscribe(
      (data: Solicitacao) => {
        this.list() 
        this._snackBar.open("Solicitação Recusada com sucesso!", "", { duration: 1000, panelClass: ['app-primary'] })       
      }
    );
  }  

}