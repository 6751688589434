import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { ContraSenha } from '../model/contra-senha.model';
import { urlList } from '../model/remote_url';
import { Observable } from 'rxjs';


@Injectable()
export class ContraSenhaService extends GenericService<ContraSenha>{
    
    public constructor(protected http: HttpClient){            
        super(http, ContraSenha)
        this.urlList = urlList['contra-senha']
    }        
        
    public gerarContraSenha(nivel: string, seed: string, senha: string): Observable<ContraSenha> {
        return this.http.get<ContraSenha>(this.urlList['gerar']+'/gerar/'+nivel+'/'+seed+'/'+senha, this.httpOptions);
    } 
}
