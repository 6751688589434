import { Component, OnInit, Injectable } from '@angular/core';
import { AuthService } from '../../shared/service/auth.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers:  [AuthService]
})

@Injectable()
export class LoginComponent implements OnInit {

  private token: string;

  public loginForm = this.formBuilder.group({
    username: '',
    password: ''
  })

  constructor(private authService: AuthService, private formBuilder: FormBuilder, private router: Router, private _snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  onSubmit(data){
    this.authService.login(data.username, data.password).subscribe(
      (data: any) => {
        this.token = data.token
        localStorage.setItem('token', this.token);    
        this._snackBar.dismiss(); 
        this.router.navigate(['/admin/home'])
      },
      (error: any) => {
        this._snackBar.open("Usuário ou Senha inválidos ", "", { duration: 15000, panelClass: ['app-alert'] })       
      }
    );        
  }
}
