import { Component, Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ContraSenhaService } from '../../../shared/service/contra-senha.service';
import { ContraSenha } from '../../../shared/model/contra-senha.model';
import { Nivel } from '../../../shared/model/nivel.model';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { GenericComponent } from 'src/app/components/generic/generic.component';
import { AuthService } from 'src/app/shared/service/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Role } from 'src/app/shared/model/role';

@Component({
  selector: 'app-contrasenha-form',
  templateUrl: './contrasenha-form.component.html',
  styleUrls: ['./contrasenha-form.component.css'],
  providers: [ContraSenhaService]
})

@Injectable()
export class ContraSenhaFormComponent extends GenericComponent<ContraSenha> {
  
    public nivelOptions: Nivel[] = [new Nivel(1, "Básico"), new Nivel(2, "Avançado")];
  
    constructor(protected service: ContraSenhaService,
      protected router: Router,
      private formBuilder: FormBuilder,
      protected authService: AuthService,
      private route: ActivatedRoute,   
      private _snackBar: MatSnackBar) {

      super(authService)
    }

    public form = this.formBuilder.group({
      nivel: ['', Validators.required],
      seed: ['', Validators.required],
      senha: ['', Validators.required],    
      contra_senha: ['']
    })

    ngOnInit() {    

    }  

    gerarContraSenha(data) {
        if (this.form.invalid) {
            return false;
        }
        this.element = data;        
        this.service.gerarContraSenha(this.element.nivel, this.element.seed, this.element.senha).subscribe(
            (data: ContraSenha) => {                     
                this.form.patchValue(
                    data
                )
            },
            (err) => {                
                this._snackBar.open("Ocorreu um erro ao gerar a contra-senha. Verifique se as informações digitadas estão corretas", "", 
                { duration: 3000, panelClass: ['app-alert'] }) 
                return false
            }
        );
    }        
    
    onChangeSelect(data) {
        this.form.get('seed').setValue("")
        this.form.get('senha').setValue("")
        this.form.get('contra_senha').setValue("")
    }  
}
